import React, {useState, useEffect} from 'react'
import { Row, Col, Button, Icon } from 'react-materialize';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Error from "./error"
import ReCAPTCHA from 'react-google-recaptcha'


const contactSchema = Yup.object().shape({
  name: Yup.string()
          .min(2, "Le nom doit avoir au moins 2 caractres")
          .required("Vous devez entrer votre nom"),
  email: Yup.string()
          .email("Entrez une adresse email valide")
          .required("Vous devez entrer votre email"),
  message: Yup.string()
          .min(2, "Le message doit avoir au moins 2 caractres")
          .required("Vous devez entrer votre message"),

})

const ContactForm = () => {

  const [bot, setBot] = useState()
  const [screenSize, setScreenSize] = useState(0)

  const handleCaptcha = () =>{
    setBot(true)
  }


  useEffect(() => {
    setScreenSize(window.innerWidth)
  },[screenSize])

  // const recaptchaRef = React.createRef();
  // const recaptchaReset = recaptchaRef.reset();

  return (
    <div>
      <Formik 
        initialValues={{ name: '', email: '', message: '' }}
        validationSchema={contactSchema}
        onSubmit={(values, {setSubmitting, resetForm, handleReset}) => {
          setSubmitting(true)
          fetch('https://api.formik.com/submit/villasante/villa-sante', {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          })
          .then(() =>{
            resetForm()
            setBot(false)
          });
                
        }}
      >
        {({ 
            // values, 
            errors, 
            touched, 
            isSubmitting, 
            handleReset 
          }) => (
          <Form >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />

            <Field type="text" name="name" placeholder="Votre nom" />
            <Error touched={touched.name} message={errors.name} />
            <Field type="email" name="email" placeholder="Votre email" />
            <Error touched={touched.email} message={errors.email} />
            <Field 
              className="materialize-textarea"
              name="message" 
              component="textarea" placeholder="Votre message"
            />
            <Error touched={touched.message} message={errors.message} />
            <div className="">
            <ReCAPTCHA
              sitekey="6LdCcqQZAAAAAP5IyCMdM7GWD0zRITko2Ls8BBiV"
              className="captcha"
              onChange={handleCaptcha}
              size={screenSize < 481 ?"compact": "normal"}
            />
            </div>
            <ul className="actions">
              <Row>
                <Col s={6}>
                  <Button
                    className="color2-bg color0"
                    type="submit"
                    waves="light"
                    left
                    disabled={isSubmitting || !bot}
                  >
                    Envoyer
                    <Icon className="hide-on-med-and-down" right>send</Icon>
                  </Button>
                </Col>
                <Col s={6}>
                  <Button
                    className="color2-bg color0 right"
                    type="reset"
                    waves="light"
                    onClick={handleReset}
                    disabled={isSubmitting}
                  >
                    Effacer
                    <Icon className="hide-on-med-and-down" right>autorenew</Icon>
                  </Button>
                </Col>
              </Row>
            </ul>
          </Form>
        )}
      </Formik>
    </div>
  )};

export default ContactForm;
