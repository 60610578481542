import React, {useRef, useEffect} from "react"
import SEO from "../components/seo"
import HeaderContact from "../components/headerContact"
import {Row, Col, Icon} from 'react-materialize';
import ContactForm from "../components/contactForm";
import Map from "../components/map";
import {gsap} from 'gsap'
import Layout from "../components/layout"


const Contact = () => {
  // let text = [useRef(null), useRef(null)]
  let card = [useRef(null), useRef(null)]

  useEffect(() => {
    gsap.from(card, {opacity: 0, duration: .8, y: 20, ease: "back", delay: .4, stagger: 0.1})
  })

  return(
  <Layout>
    <SEO title="Gyn&eacute;cologie - Obst&eacute;trique - OBGYN - Sant&eacute; - Haiti - Contact" />
    <HeaderContact />
    <div className="pages-body">
      <section className="contact-info color-0">
        <Row>
          <Col l={6} m={6} s={12}>
            <div ref={el => card[0] = el} className="card-panel contact-info-list center color-1-text">
              <Icon> email </Icon>
              <p>info@villasantehaiti.com</p>
              <Icon> phone </Icon>
              <p>+50937262329, +50934581176</p>
              <Icon> place </Icon>
              <p>15, angle rues Métellus et Borno (entrée de Montagne Noire), PV, Haiti</p>
            </div>
            
          </Col>
          <Col l={6} m={6} s={12}>
            <div ref={el => card[1] = el} className="card-panel center color-1-text"> 
              <h6>Ou &eacute;crivez-nous ici</h6>
              <ContactForm />
            </div>
          </Col>
        </Row>
      </section>
      <section className="map">
        <Map />
      </section>
    </div>
  </Layout>
)}

export default Contact