import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'

const Map = () => {

  return (

    <LoadScript
      id="script-loader"
      googleMapsApiKey="AIzaSyDkuc5LSrIlRlKP_rHwVZJUZv8bEyOsCTM"
    >
      <GoogleMap
        id="villasante-map"
        mapContainerStyle={{
          height: "100%",
          width: "100%"
        }}
        zoom={15}
        center={{
          lat: 18.506423,
          lng: -72.283771
        }}
      >
        <Marker
          position={{
            lat: 18.506423,
            lng: -72.283771
          }}
          label="Villa Sant&eacute;"
        />
        
      </GoogleMap>
    </LoadScript>
  );
};

export default Map;